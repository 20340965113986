import { Component } from 'react'
import Joi from "joi-browser"
import Input from './input'

class Form extends Component {
    state = { 
        data: {},
        errors: {}
     }
     validate = () => {
        const {error} = Joi.validate(this.state.data,this.schema,{abortEarly: false})
        if (!error) return null
        const errors = {}
        for (let item of error.details) {
            errors[item.path[0]] = item.message
        }
        
        return errors
    }
    validateProperty = ({name,value}) => {
        const obj = {[name]: value}
        const schema = {[name]: this.schema[name]}
        const {error} = Joi.validate(obj,schema)
        return error ? error.details[0].message : null
    }
    handleSubmit = e => {
        e.preventDefault()

        const errors = this.validate()
        this.setState({errors: errors || {}})
        if (errors) return 
        this.doSubmit()
    }
    handleChange = ({currentTarget: input}) => {
        const errors = {...this.state.errors}
        const errorMessage = this.validateProperty(input)
        if(errorMessage) errors[input.name] = errorMessage
        else delete errors[input.name]

        const data = {...this.state.data}
        data[input.name] = input.value
        this.setState({data,errors})
    }
    renderButton = label => {
        return <button disabled={this.validate()} type="submit" className="btn btn-primary shadow-sm rounded">{label}</button>
    }
    renderInput = (name,label,type) => {
        return <Input name={name} value={this.state.data[name]} label={label} onChange={this.handleChange} type={type} error={this.state.errors[name]}/>
    }
}
 
export default Form;