import React from 'react'

const NotFound = () => {
    return ( 
        <div className="container my-sm-5 p-5 w-50" style={{border:1,backgroundColor:'#cbd4e7'}}>
            <h1 className="text-center text-primary bolder">404 Page Not Found</h1>
        </div>
     );
}
 
export default NotFound;